body {
	font-size: 16px;
	font-family: 'Roboto';
}

.reset-list {
	margin: 0;
	padding: 0;
}

.inline-block {
	display: inline-block;
	*display: inline;
	zoom: 1;
}

.transition(@args:200ms) {
  -webkit-transition: @args;
  -moz-transition: @args;
  -o-transition: @args;
  -ms-transition: @args;
  transition: @args;
}

img {
	max-width: 100%;
	height: auto;
}

.pagination {
	.reset-list;
	margin-top: 2rem;
	text-align: center;

	li {
		.inline-block;
		list-style: none;
		margin: 0 .2rem;

		span,
		a {
			display: block;			
			padding: .5rem 1rem;
		}

		span {
			background: @primary-color;
			color: @terceary-color;
		}

		a {
			background: @terceary-color;
			color: @primary-color;

			&:hover {
				background: @primary-color;
				color: @terceary-color;
			}
		}

		&.disabled span {
			background: fade(@primary-color, 65)
		}
	}
}

#recaptcha2,
#recaptcha1 {
	overflow: hidden;
}
.error{
	color: red;
}