footer{
	margin-top: 4%;
	.bar-red{
		background: @secondary-color;
		height: 3px;
	}
	.main-footer{
		background: @primary-color;
		.logo-footer{
			margin-top: 3%;
			margin-bottom: 3%;
		}
		.contact{
			margin-top: 5%;
			margin-bottom:3%;
			text-align: center;
			.title-contact{
				font-family: 'ubuntulight';
				text-transform: uppercase;
				font-size: 2.0rem;
				color: @terceary-color;
			}
			.adress-contact{
				margin-top: 1%;
				font-family: 'ubuntulight';
				text-transform: uppercase;
				font-size: 1.2rem;
				color: @terceary-color;
			}
		}
		.shares{
			margin-top: 5%;
			margin-bottom:3%;
			.title-shares{
				font-family: 'ubuntulight';
				text-transform: uppercase;
				font-size: 2.0rem;
				color: @terceary-color;
			}
			i{
				color: @terceary-color;
			}
		}
	}
}