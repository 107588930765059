@font-face {
    font-family: 'nova_square';
    src: url('fonts/nova-square/novasquare-webfont.woff2') format('woff2'),
         url('fonts/nova-square/novasquare-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ubuntulight';
    src: url('fonts/ubuntu-light/ubuntu-l-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-light/ubuntu-l-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ubuntumedium';
    src: url('fonts/ubuntu-medium/ubuntu-m-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-medium/ubuntu-m-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}