//load libs
@import "../libs/normalize.less";
@import "../libs/bootstrap.less";
@import "../libs/slick/slick.less";
@import "../libs/slick/slick-theme.less";
@import "../libs/font-awesome.less";
// @import "../libs/sweetalert.less";
@import "../../fonts/fonts.less";

//Custom
@import "../core/variables.less";
@import "../core/core.less";

@import "../modules/header.less";
@import "../modules/footer.less";

*:focus,
*:active {
	outline: none;
}

.slider-home{
	position: relative;

	.slick-slide {
		position: relative;
	}

	.desc-slider{
		font-family: 'ubuntulight';
		color: @terceary-color;
		position: absolute;
		bottom: 3rem;
		right: 6rem;
		text-transform: uppercase;
		font-size: 3.0rem;
		text-shadow: 2px 1px 4px #2f2f2f;

		@media(max-width:640px){
			font-size: 1.4rem
		}
	}
}

.carrosel-lineproducts {
	margin: 2rem 0;

	.slick-prev:before, .slick-next:before{
		color: #1e763d;
	}

	.slick-prev {
		@media screen and (max-width: 567px) {
			left: 25px;
			z-index: 9999;

			&:before{
				color: #fff;
			}			
		}
	}

	.slick-next {
		@media screen and (max-width: 567px) {
			right: 25px;
			z-index: 9999;

			&:before{
				color: #fff;
			}
		}
	}

	
}

.main-line-products{
	padding: 4rem 0;

	.title-pages{
		font-family: 'ubuntumedium';
		color: @primary-color;
		text-align: center;
		font-size: 2.0rem;
		text-transform: uppercase;
		font-weight: 700;
	}
	.desc-line-products{
		margin-bottom: 3rem;
		color: @primary-color;
		font-family: 'ubuntulight';
		text-align: center;
		font-weight: 600;
	}
	.block-lines {
		padding: 2rem;

		&.block-1 {
			background: #129b42;
		}

		&.block-2 {
			background: #fff;
			color:  #129b42;

			.title-lines {
				color: #129b42;
			}

			h5 {
				color:  #129b42;
			}

			button {
				display: inline-block;
				padding: .5rem 2rem;
				margin: 2rem 0;
				background: #129b42;
				border-radius: 5px;
				color: #fff;
				border: 0;
			}
		}

		a {
			display: block;
			text-decoration: none;
			text-align: center;
			color: #fff;
			img {
				max-width: 120px;
    			margin: 1rem auto;
			}

			h5 {
				font-weight: 500;
			    font-size: 1.5rem;
			    margin: 0;
			}

			.title-lines{
				font-family: 'nova_square';
				font-size: 1.8rem;
				margin: .5rem 0;
				text-transform: uppercase;
				color: #fff;
			}

			button {
				display: inline-block;
				padding: .5rem 2rem;
				margin: 2rem 0;
				background: #fff;
				border-radius: 5px;
				color: #7c7c7c;
				border: 0;
			}

			&:focus{
			    outline: none;
			}
		}
	}
}

.main-institutionally{
    background: url(../../../public/img/background-company.jpg);
    background-size: cover;
    margin-top: 2%;
    .title-institutionally{
    	text-align: center;
    	font-family: 'ubuntulight';
    	color: @terceary-color;
    	font-size: 2.4rem;
    	padding: 0% 15%;
    	margin-top: 6%;
    	margin-bottom: 6%;
    }
    .desc-institutionally{
    	font-family: 'ubuntulight';
    	font-size: 1.6rem;
    	color: @terceary-color;
    	padding-bottom: 7%;
    }
}
.main-partners{
	.header-partners{
		color: @primary-color;
		font-family: 'ubuntumedium';
		text-transform: uppercase;
		font-size: 2.0rem;
		margin-top: 1%;
		margin-bottom: 1%;
		.arrow-left{
			cursor: pointer;
		}
		.arrow-right{
			cursor: pointer;
		}
	}
	.slide-partners{
		.item{
			border: 1px solid #d4d4d4;
			margin-right: 10px;
    		border-radius: 3px;
		}
	}
}