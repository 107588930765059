header{
	.bar-phone{
		background: @primary-color;
    	margin-bottom: 1%;
		.phone-right{
			float: right;
			padding: 5px;
			color: @terceary-color;
			font-family: 'nova_square'
		}
		.restricted-area{
			margin-right: 15px;
			float: right;
			padding: 5px;
			font-family: 'nova_square';
			a{
				color: #fff;
    			font-size: 14px;
    			text-decoration: underline;
			}
		}
		.ticket{
			margin-right: 15px;
			float: right;
			padding: 5px;
			font-family: 'nova_square';
			a{
				color: #fff;
    			font-size: 14px;
    			text-decoration: underline;
			}
		}
	}
	.main-bar{
		.nav{
			margin-top: 7%;
			li{
				list-style: none;
				display: inline-block;
				padding-left: 6px;
				@media(max-width: 1024px)
				{
					padding-left: 0;
				}
				a{
					transition: 0.3s;
				    -web-kit-transition: 0.3s;
				    padding: 80px 9px 19px;
				    color: #1e763d;
				    text-decoration: none;
				    font-family: 'nova_square';
				    text-transform: uppercase;
					&:hover{
						background-color: @primary-color;
						color: @terceary-color;
					}
					@media(max-width: 1024px)
					{
						padding: 70px 4px 19px;
					}
				}
				.active{
					background-color: @primary-color;
					color: @terceary-color;	
				}
			}
			@media(max-width: 768px) 
			{
				position:  fixed;
				top: 0;
				width: 300px;
				height: 100%;
				left: -300px;
				background: @primary-color;
				z-index: 999;
				-webkit-transition: 1s; 
				transition: 1s;
				li{
					display: block;
					margin-top: 3%;
					margin-top: 10%;
					padding: 1% 2%;
					a {
						color: #fff;
						padding: 0;
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
.btn-menu {
	display: none;
	float: right;
	@media(max-width: 768px) {
		display: block;
		cursor: pointer;
		text-align: center;
		color: @primary-color;
		padding: 1% 2%;
	}
}
body.navIsActive {

	.main-bar .nav{
		@media(max-width: 768px) {
			left: 0;
		}
	}
}

.pulse {
  animation: pulse 0.7s infinite;
  margin: 0 auto;
  display: table;
  margin-top: 50px;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.3);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}